<div
  (click)="hideDrawer()"
  drawer-backdrop=""
  class="fixed inset-0 z-30 bg-red-600 bg-opacity-0 ease-linear outline-blue-500"
  [ngClass]="drawerOpen === true ? 'visible md:hidden' : 'hidden md:visible'"
></div>

@if (drawerOpen) {
  <div class="absolute z-[100] top-16 left-0 right-0 border bg-white shadow-lg md:hidden">
    <div class="m-4">
      <app-user-button></app-user-button>
    </div>
    <div class="bg-white block">
      @for (child of navigationConfig$ | async; track child) {
        <a
          class="inline-flex items-center w-full uppercase md:flex border-l-4 hover:no-underline text-gray-600 before:border-white after:border-white"
          [routerLink]="child.segment"
          routerLinkActive="border-magnit-orange text-magnit-blue"
          [matMenuTriggerFor]="menu"
          [matMenuTriggerData]="{ children: child.children }"
        >
          <span class="leading-10 h-10 overflow-hidden font-medium text-ellipsis mx-4 whitespace-nowrap">{{
            child.label | translate
          }}</span>
          @if (child.children?.length) {
            <i-feather [name]="false ? 'chevron-up' : 'chevron-down'"></i-feather>
          }
        </a>
      }
    </div>
  </div>
}

<div class="h-screen flex flex-col">
  <div class="flex top bg-white border-bottom w-full h-18 justify-between">
    <nav class="bg-white z-30 flex-1 inline-flex w-auto max-w-[1500px] text-md mx-auto py-1">
      <div class="flex flex-1 items-center justify-start h-16">
        <a routerLink="/supplier/home" class="text-xl font-bold flex items-center ml-3 flex-none text-center">
          <img
            src="https://brainnet-cdn-ep-content.azureedge.net/logo-magnit_mark-only_204x270.svg"
            class="h-12 w-12 mr-4 m-auto"
            alt="Magnit Global Logo"
          />
        </a>

        <div class="flex items-center justify-start h-16 font-medium text-magnit-black">
          @for (child of navigationConfig$ | async; track child) {
            <a
              class="uppercase pr-2 pl-2 hidden h-8 md:flex border-b-2 cursor-pointer text-magnit-black hover:text-magnit-blue border-red"
              [routerLink]="child.segment"
              routerLinkActive="border-magnit-orange text-magnit-blue"
              [matMenuTriggerFor]="child.children?.length ? menu : null"
              [matMenuTriggerData]="{ children: child.children }"
            >
              <span class="self-center overflow-hidden text-ellipsis whitespace-nowrap">{{
                child.label | translate
              }}</span>
              @if (child.children?.length) {
                <span class="self-center border-t-4 border-transparent">
                  <mat-icon aria-hidden="false" aria-label="more" fontIcon="expand_more"></mat-icon>
                </span>
              }
            </a>
          }

          @if (auth.isAdmin$ | async) {
            <a
              class="uppercase mr-2 ml-2 text-magnit-black border-b-2 border-solid hidden md:block"
              routerLink="/admin/home"
              routerLinkActive="border-magnit-orange text-magnit-blue"
            >
              Admin
            </a>
          }
        </div>
      </div>
      @if (auth.boabaasUser$ | async; as boabaasUser) {
        <div class="m-auto p-2 inline-flex items-center">
          <div class="text-magnit-black pr-3 whitespace-nowrap text-right hidden md:block">
            <app-date-label></app-date-label>
          </div>
          <div class="hidden md:block m-auto">
            <app-user-button />
          </div>
          <div class="md:hidden mr-10"><app-date-label></app-date-label></div>
        </div>
      }
      <div class="px-2 flex md:hidden">
        <button
          (click)="toggleDrawer()"
          title="toggleSidebarMobile"
          id="toggleSidebarMobile"
          aria-expanded="true"
          class="absolute right-3 top-3 z-50 m-auto cursor-pointer p-2 focus:border-transparent border border-slate-200 bg-magnit-blue focus:ring-4 focus:ring-gray-300 rounded-[4px] transition-all duration-300"
          [ngClass]="{
            'text-white': drawerOpen,
            'bg-magnit-blue': drawerOpen,
            'border-red-600': drawerOpen,
          }"
        >
          <svg
            id="toggleSidebarMobileHamburger"
            class="w-6 h-6"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clip-rule="evenodd"
            ></path>
          </svg>
          <svg
            id="toggleSidebarMobileClose"
            class="w-6 h-6 hidden"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
      </div>
    </nav>
  </div>
  @if (unavailabilityNotice().isActive) {
    <div class="bg-background-100 pb-1 pt-3">
      <app-unavailability-notice [message]="unavailabilityNotice().message"></app-unavailability-notice>
    </div>
  }
  @if (init | call | async; as principal) {
    <div class="flex justify-center flex-1">
      <router-outlet></router-outlet>
    </div>
  } @else {
    <app-loader />
  }
</div>

<mat-menu #menu="matMenu">
  <ng-template matMenuContent let-children="children">
    <div>
      @for (child of children; track child) {
        <button (click)="hideDrawer()" mat-menu-item matRipple [routerLink]="child.segment">
          <span class="uppercase font-medium text-[14px] font-magnit">{{ child.label | translate }}</span>
        </button>
      }
    </div>
  </ng-template>
</mat-menu>

<div class="portal-data-loader">
  <app-loader></app-loader>
</div>

<app-maintenance-page></app-maintenance-page>
