@if (!hidden) {
  <div class="mb-4">
    <div class="mb-2 text-gray-dark">{{ title | translate }}</div>
    <div class="d-flex">
      <div>
        @if (!isCustomIcon) {
          <i-feather [name]="icon" [class]="iconclass"></i-feather>
        }
        @if (isCustomIcon) {
          <img alt="{{ title | translate }}" [class]="iconclass" [src]="getSourceLocation()" />
        }
      </div>
      <div class="ml-2">
        <span class="font-weight-bold">
          <ng-content></ng-content>
        </span>
      </div>
    </div>
  </div>
}
