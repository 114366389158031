<button
  type="button"
  [title]="title"
  class="border rounded border-magnit-blue text-magnit-blue bg-white inline-flex p-2 hover:text-white hover:bg-magnit-blue transition-colors duration-300"
>
  @if (icon) {
    <i-feather class="w-4 h-4" [name]="icon"></i-feather>
  }
  <span class="text-base ml-3"> {{ title }} </span>
</button>
