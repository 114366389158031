import { Component, ContentChildren, QueryList, AfterContentInit } from '@angular/core';

import { AccordionItemComponent } from './accordion-item.component';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  standalone: true,
})
export class AccordionComponent implements AfterContentInit {
  @ContentChildren(AccordionItemComponent)
  items!: QueryList<AccordionItemComponent>;

  ngAfterContentInit() {
    this.items.toArray().forEach((item) => {
      item.expandedChange.subscribe((expanded: boolean) => {
        if (expanded) {
          this.closeOtherItems(item);
        }
      });
    });
  }

  closeOtherItems(selectedItem: AccordionItemComponent) {
    this.items.toArray().forEach((item) => {
      if (item !== selectedItem) {
        item.expanded = false;
      }
    });
  }
}
