import { Component, Input } from '@angular/core';
import { FeatherModule } from 'angular-feather';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-icon-label',
  templateUrl: './icon-label.component.html',
  styleUrls: ['./icon-label.component.scss'],
  standalone: true,
  imports: [FeatherModule, TranslateModule],
})
export class IconLabelComponent {
  @Input() title = ``;
  @Input() icon = ``;
  @Input() iconclass = `text-gray-dark`;
  @Input() isCustomIcon = false;

  hidden: boolean | null = null;

  getSourceLocation(): string {
    return `./assets/img/icons/${this.icon}.svg`;
  }
}
