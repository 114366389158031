import { Component } from '@angular/core';
import { FeatherModule } from 'angular-feather';
import { AsyncPipe } from '@angular/common';

import { OfflineInterceptor } from '../../maintenance/offline-interceptor';

@Component({
  selector: 'app-maintenance-page',
  templateUrl: './maintenance-page.component.html',
  styleUrls: ['./maintenance-page.component.scss'],
  standalone: true,
  imports: [FeatherModule, AsyncPipe],
})
export class MaintenancePageComponent {
  isOffline$ = OfflineInterceptor.isOffline$;

  close() {
    OfflineInterceptor.isOffline$.next(false);
  }
}
