import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, catchError, Observable, of, Subject, tap } from 'rxjs';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class OfflineInterceptor implements HttpInterceptor {
  static isOffline$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 503) {
          OfflineInterceptor.isOffline$.next(true);
          return of();
        }
        throw err;
      }),
    );
  }
}
