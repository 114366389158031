@if (auth.boabaasUser$ | async; as boabaasUser) {
  <a
    class="inline-flex items-center cursor-pointer hover:text-magnit-blue"
    [matMenuTriggerFor]="menu"
    [matMenuTriggerData]="{ identities: boabaasUser.identities }"
    aria-haspopup="true"
  >
    <div class="relative inline-block text-left m-auto mr-4">
      <div>
        <span
          class="bg-magnit-orange leading-10 rounded-full w-10 h-10 min-w-10 text-white font-semibold justify-center flex shadow-slate-300 shadow-sm focus:outline-slate-200"
        >
          {{ boabaasUser.initials }}
        </span>
      </div>
    </div>
    <div>
      <div class="font-bold text-[12px]">{{ boabaasUser.fullName }}</div>
      @if (boabaasUser.activeIdentity$ | async; as activeIdentity) {
        <div class="text-magnit-blue font-normal text-[11px]">
          {{ activeIdentity.companies[0]?.name ?? 'Todo: bedrijfsnaam' }}
        </div>
      }
    </div>
    <span class="mx-1">
      <i-feather [name]="false ? 'chevron-up' : 'chevron-down'"></i-feather>
    </span>
  </a>
}

<mat-menu #menu="matMenu" xPosition="before" class="user-button-dropdown">
  <ng-template matMenuContent let-identities="identities">
    <a routerLink="/supplier/profile" tabindex="1" mat-menu-item>
      <span>{{ 'account.edit-profile' | translate }}</span>
    </a>
    <a routerLink="/supplier/support/default" tabindex="2" mat-menu-item>
      <span>{{ 'support.support' | translate }}</span>
    </a>
    <button (click)="onLogout()" tabindex="3" mat-menu-item>
      <span>{{ 'account.logout' | translate }}</span>
    </button>

    @if (identities.length > 1) {
      @for (identity of identities; track identity) {
        <app-user-identity [identity]="identity"></app-user-identity>
      }
    }
  </ng-template>
</mat-menu>
