import { Component } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { MatMenuTrigger, MatMenu, MatMenuContent, MatMenuItem } from '@angular/material/menu';
import { FeatherModule } from 'angular-feather';
import { RouterLink } from '@angular/router';
import { AsyncPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { UserIdentityComponent } from './user-identity/user-identity.component';
import { AuthenticationService } from '~/app/auth/authentication.service';
import { CompanyInfo } from 'src/app/auth/BoabaasUserService';

@Component({
  selector: 'app-user-button',
  templateUrl: './user-button.component.html',
  styleUrls: ['./user-button.component.scss'],
  standalone: true,
  imports: [
    MatMenuTrigger,
    FeatherModule,
    MatMenu,
    MatMenuContent,
    RouterLink,
    MatMenuItem,
    UserIdentityComponent,
    AsyncPipe,
    TranslateModule,
  ],
})
export class UserButtonComponent {
  accounts: CompanyInfo[] | null = null;

  constructor(
    public auth: AuthenticationService,
    private msalService: MsalService,
  ) {}

  onLogout() {
    this.msalService.logout();
  }
}
