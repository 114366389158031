<a
  (click)="toggle()"
  class="px-2 py-2 inline-flex w-full cursor-pointer z-50 relative transition box-border leading-7"
  [ngClass]="{ 'bg-[#f4f5ff]': expanded, 'text-magnit-blue': expanded }"
>
  <span class="ml-2 mr-auto text-left">
    @if (_headerTemplate) {
      <ng-template [ngTemplateOutlet]="_headerTemplate"></ng-template>
    }
  </span>

  <mat-icon
    role="img"
    aria-hidden="true"
    data-mat-icon-type="font"
    [ngClass]="{ 'rotate-180': expanded }"
    class="transition font-thin min-w-8 text-center mt-auto mb-1"
    >expand_more</mat-icon
  >
</a>

<div class="relative overflow-hidden">
  <div
    class="p-4"
    #collapsible
    [ngStyle]="{
      'margin-top': getMarginTop | call: expanded : collapsible,
    }"
  >
    @if (_bodyTemplate) {
      <ng-template [ngTemplateOutlet]="_bodyTemplate"></ng-template>
    }
  </div>
</div>
