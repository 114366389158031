<a
  [routerLink]="[url]"
  class="p-3 pr-2 bg-white font-light hover:bg-gray-200 block mat-elevation-z1 text-gray-600 transition-colors duration-300"
>
  <div class="inline-flex items-center w-full text-2xl/3">
    @if (icon) {
      <i-feather [name]="icon!" class="font-light text-magnit-counter"></i-feather>
    }
    <span class="ml-3 m-auto">{{ count }} {{ countSuffix | translate }}</span>
    <i-feather name="chevron-right"></i-feather>
  </div>
  <div class="inline-flex flex-1 w-full">
    <span class="mr-auto truncate w-full text-sm">{{ title | translate }}</span>
  </div>
</a>
