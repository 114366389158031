import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { LocalDatePipe } from '../../pipes/local-date.pipe';

@Component({
  selector: 'app-date-label',
  templateUrl: './date-label.component.html',
  standalone: true,
  imports: [TranslateModule, LocalDatePipe],
})
export class DateLabelComponent {
  today: Date;

  constructor() {
    const date = new Date();
    this.today = this.convertToUTC(date);
  }

  private convertToUTC(date: Date): Date {
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth();
    const day = date.getUTCDate();
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const seconds = date.getUTCSeconds();
    const now_utc = Date.UTC(year, month, day, hours, minutes, seconds);
    return new Date(now_utc);
  }
}
