import { Component, ContentChild, Input } from '@angular/core';
import { FeatherModule } from 'angular-feather';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  standalone: true,
  imports: [FeatherModule],
})
export class ButtonComponent {
  @Input() title?: string;
  @Input() icon?: string;
}
