@for (account of identity.companies; track account) {
  <a
    mat-menu-item
    tabindex="4"
    (click)="switchMember(identity.identifier)"
    class="shadow-inner user-identity"
    [ngClass]="{
      'user-identity-active': isActiveMember(),
    }"
    aria-disabled="false"
  >
    <span
      class="user-identity-text"
      [ngClass]="{
        'user-identity-active-text': isActiveMember(),
      }"
    >
      {{ account.name }}</span
    >
    <mat-icon
      role="img"
      aria-hidden="true"
      data-mat-icon-type="font"
      class="user-identity-icon mr-0"
      [ngClass]="{
        'user-identity-icon-active': isActiveMember(),
      }"
      >business</mat-icon
    >
  </a>
}
